"use strict";

//Language
var url = window.location.href;
if (url.indexOf("/en/") !== -1) {
  var lang = "en";
} else {
  var lang = "de";
}

// fix parameters
if (window.checkMobile() == true) {
  var fps = 10;
  var timeresolution = 6; // positive integer
} else {
  var fps = 10;
  var timeresolution = 6; // positive integer
}

var timeWindow = [0, 4];
var startButtonState = false;

// <---- user-specified parameters ---->

// Excitation
var f = 2;
var p0 = 50; // [N]

// Balken
var zeta = 0.04;
var m = 410; // [kg]
var EI = 7 * 1000000; //[N*m^2]
var l = 6; // [m]

// Darstellung
var verlangsamung = 3;

// initialize arrays
var cnt
var time;
var excitation, excitation_a;
var response, response_a;

// initialize variables
var omega, omega_n, omega_D, m_tilde, k_tilde
var A, B, C, D
var jj, a0, aj, bj
var umax, amax, Vu, Va, Vu3, k_tilde3, u1_max, u3_max


var viewSwitch;
var Anregungsfunktion;
var Y1Range = [-0.05, 0.05];
var Y2Range = [-60, 60];

// <---- initialize sliders ---->
// Slider frequency f
var xsldF = document.getElementById("sliderF");
noUiSlider.create(xsldF, {
  start: [f],
  tooltips: true,
  range: {
    min: 0.5,
    max: 10,
  },

  step: 0.1,
  pips: {
    mode: "values",
    values: [0.5, 1, 5, 10],
    density: 100,
    format: {
      to: function (value) {
        return Math.round(value * 10) / 10;
      },
      from: function (value) {
        return value;
      },
    },
  },
});
xsldF.noUiSlider.on("change", updateF);

// Slider EI
var xsldEI = document.getElementById("sliderEI");
noUiSlider.create(xsldEI, {
  start: [EI / 1000000],
  tooltips: true,
  range: {
    min: 1,
    max: 20,
  },

  step: 0.1,
  pips: {
    mode: "values",
    values: [1, 5, 10, 15, 20],
    density: 100,
    format: {
      to: function (value) {
        return Math.round(value * 100) / 100;
      },
      from: function (value) {
        return value;
      },
    },
  },
});
xsldEI.noUiSlider.on("change", updateEI);

// Slider m
var xsldM = document.getElementById("sliderM");
noUiSlider.create(xsldM, {
  start: [m],
  tooltips: true,
  range: {
    min: 100,
    max: 2000,
  },

  step: 10,
  pips: {
    mode: "values",
    values: [100, 500, 1000, 1500, 2000],
    density: 100,
    format: {
      to: function (value) {
        return Math.round(value * 100) / 100;
      },
      from: function (value) {
        return value;
      },
    },
  },
});
xsldM.noUiSlider.on("change", updateM);

// Slider l
var xsldL = document.getElementById("sliderL");
noUiSlider.create(xsldL, {
  start: [l],
  tooltips: true,
  range: {
    min: 2,
    max: 20,
  },

  step: 1,
  pips: {
    mode: "count",
    values: 10,
    density: 5,
    format: {
      to: function (value) {
        return Math.round(value * 100) / 100;
      },
      from: function (value) {
        return value;
      },
    },
  },
});
xsldL.noUiSlider.on("change", updateL);

// Slider Zeta
var xsldZeta = document.getElementById("sliderZeta");
noUiSlider.create(xsldZeta, {
  start: [zeta],
  tooltips: true,
  range: {
    min: 0.01,
    max: 0.1,
  },

  step: 0.01,
  pips: {
    mode: "values",
    values: [0.01, 0.05, 0.1],
    density: 11,
    format: {
      to: function (value) {
        return Math.round(value * 100) / 100;
      },
      from: function (value) {
        return value;
      },
    },
  },
});
xsldZeta.noUiSlider.on("change", updateZeta);

// Slider Verlangsamung
var xsldVerlangsamung = document.getElementById("sliderVerlangsamung");
noUiSlider.create(xsldVerlangsamung, {
  start: [verlangsamung],
  tooltips: true,
  range: {
    min: 1,
    max: 5,
  },

  step: 0.5,
  pips: {
    mode: "values",
    values: [1, 2, 3, 4, 5],
    density: 12.5,
    format: {
      to: function (value) {
        return Math.round(value * 100) / 100;
      },
      from: function (value) {
        return value;
      },
    },
  },
});
xsldVerlangsamung.noUiSlider.on("change", updateVerlangsamung);


// <---- start analysis ---->
startAnalysis()

function startAnalysis() {

  cnt = 0;

  // initialize arrays
  time = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);
  excitation = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);
  excitation_a = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);
  response = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);
  response_a = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);

  setResponseFactors()

  var interval = setInterval(function () {



    const start = Date.now();

    // initialize arrays
    time = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);
    excitation = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);
    excitation_a = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);
    response = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);
    response_a = math.zeros(timeWindow[1] * fps * timeresolution * verlangsamung);

    updateResponse(cnt)

    updateHTMLValues()
    var end = Date.now();
    console.log(`Execution time for computation : ${end - start} ms`);



    // plot Anregung und Antwort graphs
    var u0 = u1_max;
    // var u0_transient = math.sqrt(A * A + B * B);
    // var max_EorR = math.max(u0 + u0_transient, p0 / k_tilde);

    // var range = math.dotMultiply([-max_EorR, max_EorR], 1000);
    // range = [-2, 2]
    var ploty2 = excitation._data;
    if (viewSwitch === 'u') {
      var ploty1 = math.dotMultiply(response._data, 1000)
    } else {
      var ploty1 = response_a._data;
    }
    var data = {
      plotData: [
        {
          x: time._data,
          y: ploty1,
          type: "scatter",
          mode: "lines",
          line: {
            dash: "solid",
            width: 1,
            color: "rgb(0,0,255)",
          },
          hoverinfo: "none",
        },
        {
          x: time._data,
          y: ploty2,
          axis: 'x2',
          yaxis: 'y2',
          type: "scatter",
          mode: "lines",
          line: {
            dash: "solid",
            width: 1,
            color: "rgb(100,100,100)",
          },
          hoverinfo: "none",
        }
      ],
      plotLayout: {
        margin: {
          l: 70,
          r: 20,
          b: 50,
          t: 30,
          pad: 5,
        },
        height: 400,
        xaxis: {
          title: "Zeit <i>t</i> [sec]",
          range: timeWindow,
          domain: [0, 1]
        },
        yaxis: {
          title: "Durchbiegung <i>u(t)</i> [mm]",
          range: Y1Range,
          domain: [0, 0.45]
        },
        xaxis2: {
          range: timeWindow,
          anchor: 'y2',
          domain: [0, 1]
        },
        yaxis2: {
          title: "Anregung <i>p(t)</i> [N]",
          range: Y2Range,
          anchor: 'x2',
          domain: [0.55, 1],
        },
        showlegend: false,
        datarevision: cnt,
        dragmode: false
      },
    };
    pltConfig.displayModeBar = false;
    if (cnt == 0) {
      Plotly.newPlot('plotGraphs', data.plotData, data.plotLayout, pltConfig);
    } else {
      Plotly.react('plotGraphs', data.plotData, data.plotLayout)
    }


    if (cnt >= fps * timeresolution * verlangsamung * timeWindow[1]) {
      var update = {
        'xaxis.range': [cnt / fps / timeresolution / verlangsamung - timeWindow[1], cnt / fps / timeresolution / verlangsamung],   // updates the xaxis range
      };
      Plotly.relayout('plotGraphs', update)
    }

    // Plot Static system
    let tempSec = new Group();
    tempSec.addElm(new Support(-0.5, 0, 1, 0.05));
    tempSec.addElm(new Support(0.5, 0, 2, 0.05));
    tempSec.addElm(new Line([-0.5, 0.5], [0, 0]).setDash('dash'));
    tempSec.addElm(new DimLine([-0.5, -0.15], [0.5, -0.15], "Länge <i>l</i>", 0.008, 0.025, -0.03));
    var excitationVal = excitation._data[math.min(cnt, excitation._data.length - 1)];
    var responseVal = response._data[math.min(cnt, response._data.length - 1)];
    let xCurve = [];
    let yCurve = [];
    for (let j = 0; j < 101; j++) {
      xCurve[j] = 1 / 100 * j - 0.5;
      if (cnt == 0) {
        yCurve[j] = 0;
      } else {
        yCurve[j] = -math.sin(math.PI * 1 / 100 * j) * responseVal * 0.03 / (u0);
      }
    }
    tempSec.addElm(new Line(xCurve, yCurve));
    if (cnt == 0) {
      tempSec.addElm(new Arrow(0, 0, math.abs(0.1), (1 - math.sign(1)) / 2 * 180, 0.1));
    } else {
      // tempSec.addElm(new Arrow(0, 0, math.abs(0.1 * excitationVal / (p0)), (1 - math.sign(excitationVal)) / 2 * 180, 0.1));
      if (math.sign(excitationVal) === 1) {
        tempSec.addElm(new Arrow(0, 0, math.abs(0.1 * excitationVal / (Y2Range[1])), 0, 0.1));
      } else if (math.sign(excitationVal) === -1) {
        tempSec.addElm(new Arrow(0, math.min(-math.abs(0.1 * excitationVal / (Y2Range[1])), -0.1 * 0.4), math.abs(0.1 * excitationVal / (Y2Range[1])), 180, 0.1));
      }
    }

    if (cnt > 0) {
      tempSec.addElm(new Line([-0.01 + 0.57, 0.01 + 0.57], [0, 0]));
      // tempSec.addElm(new Arrow(0.57, -0.05, 0.05, 0, 0.06));
      if (math.sign(responseVal) === 1) {
        tempSec.addElm(new Arrow(0.57, math.min(-0.03 * responseVal / (u0), -0.04 * 0.4), 0.03 * responseVal / (u0), 0, 0.04));
      } else if (math.sign(responseVal) === -1) {
        tempSec.addElm(new Arrow(-0.57, 0.03 * responseVal / (u0), -0.03 * responseVal / (u0), 180, 0.04));
      }
      tempSec.addElm(new Line([0, 0.01 + 0.57], [-0.03 * responseVal / (u0), -0.03 * responseVal / (u0)]).setDash('dot'));
    }

    let layout = {
      hovermode: false,
      height: 150,

      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 0,
      },
      xaxis: {
        domain: [0, 1],
        range: [-0.6, 0.7],
        showgrid: false,
        zeroline: false,
        showline: false,
        ticks: "",
        showticklabels: false,
      },

      yaxis: {
        domain: [0, 1],
        constrain: 'range',
        constraintoward: 'center',
        scaleanchor: 'x',
        scaleratio: 1,
        range: [-0.2, 0.1],
        showgrid: false,
        zeroline: false,
        showline: false,
        ticks: "",
      },


      showlegend: false,

      datarevision: cnt,
      dragmode: false
    };
    if (window.checkMobile() == true) {
      layout.dragmode = false;
    }

    let pltSec = tempSec.getPlotData();
    var data = pltSec.data;

    var text1 = {
      x: [0.02],
      y: [0.08],
      mode: 'text',
      name: 'Lines, Markers and Text',
      text: ['<i>p</i>(t) = <i>p<sub>0</sub></i> sin(2<i>πf</i>)'],
      textposition: 'middle right',
      type: 'scatter'
    };
    if (Anregungsfunktion === 1) {
      text1.text = ['<i>p</i>(t) = <i>p<sub>0</sub></i> [0.4sin(2<i>πf</i>)-0.2cos(4<i>πf</i>)-0.1cos(6<i>πf</i>)]'];
    }

    var text2 = {
      x: [0.585],
      y: [0],
      mode: 'text',
      name: 'Lines, Markers and Text',
      text: ['<i>u</i>(t)'],
      textposition: 'middle right',
      type: 'scatter'
    };
    data.push(text1)
    if (cnt > 0) {
      data.push(text2)
    }

    // pltConfig.doubleClick = false;
    pltConfig.displayModeBar = false;
    // pltConfig.modeBarButtons = [];


    var plotElement = document.getElementById("plotElement");
    if (cnt == 0) {
      Plotly.newPlot(plotElement, data, layout, pltConfig);
    } else {
      Plotly.react(plotElement, data, layout);
    }

    if (viewSwitch === 'u') {
      var update = {
        'yaxis.title': "Durchbiegung <i>u(t)</i> [mm]",   // updates the xaxis range
      }
    } else {
      var update = {
        'yaxis.title': "Beschleunigung <i>a(t)</i> [m/s<sup>2</sup>]",   // updates the xaxis range
      }
    }
    Plotly.relayout('plotGraphs', update)


    // if (cnt == fps * timeresolution * verlangsamung * timeEnd) clearInterval(interval);
    // if (cnt > 1) clearInterval(interval);
    if (startButtonState == false) clearInterval(interval);

    end = Date.now();
    console.log(`Execution time for plotting : ${end - start} ms`);
    cnt = cnt + timeresolution;
  }, 1000 / fps);
}

function updateResponse(cnt) {

  Anregungsfunktion = parseInt($("#Anregungsfunktion").val());
  var checkbox = document.getElementById("einschwingvorgang");
  viewSwitch = document.querySelector('input[name="view"]:checked').id;


  // Analyzsis
  omega = 2 * math.PI * f;
  omega_n = math.PI * math.PI / (l * l) * math.sqrt(EI / m); // Eq. (a) p.711
  omega_D = omega_n * math.sqrt(1 - math.pow(zeta, 2));

  m_tilde = m * l / 2; // Eq. (b) p.711
  k_tilde = math.pow(math.PI, 4) * EI / (2 * math.pow(l, 3)); // Eq. (b) p.711


  if (Anregungsfunktion === 0) {
    p0 = 50;

    // sinus excitation
    C = p0 / k_tilde * (1 - math.pow((omega / omega_n), 2)) / (math.pow((1 - math.pow((omega / omega_n), 2)), 2) + math.pow((2 * zeta * omega / omega_n), 2));
    D = p0 / k_tilde * (-2 * zeta * omega / omega_n) / (math.pow((1 - math.pow((omega / omega_n), 2)), 2) + math.pow((2 * zeta * omega / omega_n), 2));


    if (checkbox.checked == true) {
      A = -D;
      B = -(zeta * omega_n * D + omega * C) / omega_D;
    } else {
      A = 0;
      B = 0;
    }
  } else if (Anregungsfunktion === 1) {
    p0 = 700;

    // schritte excitation
    jj = [[1], [2], [3]];
    a0 = -p0 * 0.1448 * 0;
    bj = math.dotMultiply([[0.4], [0], [0]], p0);
    aj = math.dotMultiply([[0], [-0.2], [-0.1]], p0);

  }




  // Vu = 1 / math.sqrt(math.pow((1 - math.pow((omega / omega_n), 2)), 2) + math.pow((2 * zeta * omega / omega_n), 2));
  Vu3 = 1 / math.sqrt(math.pow((1 - math.pow((omega / (9 * omega_n)), 2)), 2) + math.pow((2 * zeta * omega / (9 * omega_n)), 2));

  k_tilde3 = k_tilde * math.pow(3, 4)

  u1_max = p0 / k_tilde * Vu;
  u3_max = p0 / k_tilde3 * Vu3

  // Va = omega * omega / omega_n / omega_n * Vu;






  var t;
  for (var i = 0; i < excitation._data.length; i++) {
    if (cnt < excitation._data.length) {
      if (i > cnt) {
        response._data[i] = NaN;
        response_a._data[i] = NaN;
        excitation._data[i] = NaN;
        excitation_a._data[i] = NaN;
        // break;
      } else {
        t = i / fps / timeresolution / verlangsamung;
      }
    } else {
      t = (i + cnt - excitation._data.length) / fps / timeresolution / verlangsamung;
    }
    // compute response for time t
    time._data[i] = t;

    if (Anregungsfunktion === 0) {
      excitation._data[i] = p0 * math.sin(omega * t);
      response._data[i] = math.exp(-zeta * omega_n * t) * (A * math.cos(omega_D * t) + B * math.sin(omega_D * t)) + C * math.sin(omega * t) + D * math.cos(omega * t);
      response_a._data[i] =
        math.exp(-zeta * omega_n * t) * (-A * omega_D * omega_D * math.cos(omega_D * t) - -B * omega_D * omega_D * math.sin(omega_D * t)) +
        omega_n * omega_n * zeta * zeta * math.exp(-zeta * omega_n * t) * (A * math.cos(omega_D * t) + B * math.sin(omega_D * t)) - 2 * omega_n * zeta *
        math.exp(-zeta * omega_n * t) * (B * omega_D * math.cos(omega_D * t) - A * omega_D * math.sin(omega_D * t))
        - C * omega * omega * math.sin(omega * t) - D * omega * omega * math.cos(omega * t);
    } else if (Anregungsfunktion === 1) {
      excitation._data[i] = a0 + math.sum(math.dotMultiply(aj, math.cos(math.dotMultiply(jj, omega * t)))) + math.sum(math.dotMultiply(bj, math.sin(math.dotMultiply(jj, omega * t))));

      var u = a0 / k_tilde;
      var a = 0;

      A = 0;
      B = 0;
      for (var jjj = 0; jjj < jj.length; jjj++) {
        var betaj = jj[jjj][0] * omega / omega_n;
        var u_sinTerms = 1 / (math.pow(1 - math.pow(betaj, 2), 2) + math.pow(2 * zeta * betaj, 2)) * (aj[jjj][0] * 2. * zeta * betaj + bj[jjj][0] * (1 - math.pow(betaj, 2)));
        var u_cosTerms = 1 / (math.pow(1 - math.pow(betaj, 2), 2) + math.pow(2 * zeta * betaj, 2)) * (aj[jjj][0] * (1 - math.pow(betaj, 2)) - bj[jjj][0] * 2. * zeta * betaj);

        u = u + 1 / k_tilde * u_cosTerms * math.cos(jj[jjj][0] * omega * t) + 1 / k_tilde * u_sinTerms * math.sin(jj[jjj][0] * omega * t);

        a = a + 1 / k_tilde * u_cosTerms * -math.cos(jj[jjj][0] * omega * t) * math.pow(jj[jjj][0] * omega, 2) + 1 / k_tilde * -u_sinTerms * math.sin(jj[jjj][0] * omega * t) * math.pow(jj[jjj][0] * omega, 2);

        A = A - u_cosTerms / k_tilde;
        B = B + u_sinTerms / k_tilde * jj[jjj][0] * omega;

      }

      B = -(B + zeta * omega_n * -A) / omega_D;

      if (checkbox.checked == true) {
        u = u + math.exp(-zeta * omega_n * t) * (A * math.cos(omega_D * t) + B * math.sin(omega_D * t));
        a = a + math.exp(-zeta * omega_n * t) * (-A * omega_D * omega_D * math.cos(omega_D * t) - -B * omega_D * omega_D * math.sin(omega_D * t)) +
          omega_n * omega_n * zeta * zeta * math.exp(-zeta * omega_n * t) * (A * math.cos(omega_D * t) + B * math.sin(omega_D * t)) - 2 * omega_n * zeta *
          math.exp(-zeta * omega_n * t) * (B * omega_D * math.cos(omega_D * t) - A * omega_D * math.sin(omega_D * t))
      }

      response._data[i] = u;
      response_a._data[i] = a;




    }








  }
  console.log(3 * jj)
}

function updateF(values, handle, unencoded, tap, positions) {
  f = values[0];
  updateResponse(cnt)
  setResponseFactors()
  updateHTMLValues()
}
function updateEI(values, handle, unencoded, tap, positions) {
  EI = values[0] * 1000000;
  updateResponse(cnt)
  setResponseFactors()
  updateHTMLValues()
}
function updateM(values, handle, unencoded, tap, positions) {
  m = values[0];
  updateResponse(cnt)
  setResponseFactors()
  updateHTMLValues()
}
function updateL(values, handle, unencoded, tap, positions) {
  l = values[0];
  updateResponse(cnt)
  setResponseFactors()
  updateHTMLValues()
}
function updateZeta(values, handle, unencoded, tap, positions) {
  zeta = values[0];
  updateResponse(cnt)
  setResponseFactors()
  updateHTMLValues()
}
function updateVerlangsamung(values, handle, unencoded, tap, positions) {
  verlangsamung = values[0];
  updateResponse(cnt)
  setResponseFactors()
  updateHTMLValues()
}

$("#Anregungsfunktion").change(function () {
  if (parseInt($("#Anregungsfunktion").val()) === 0) {
    Y2Range = [-60, 60];

    xsldF.noUiSlider.updateOptions({
      range: {
        'min': 0.5,
        'max': 10,
      },
      pips: {
        mode: "values",
        values: [0.5, 1, 5, 10],
        density: 100,
        format: {
          to: function (value) {
            return Math.round(value * 10) / 10;
          },
          from: function (value) {
            return value;
          },
        },
      },
    });

  } else if (parseInt($("#Anregungsfunktion").val()) === 1) {
    Y2Range = [-650, 650];

    xsldF.noUiSlider.updateOptions({
      range: {
        'min': 1.5,
        'max': 2.5,
      },
      pips: {
        mode: "values",
        values: [1.5, 2, 2.5],
        density: 10,
        format: {
          to: function (value) {
            return Math.round(value * 10) / 10;
          },
          from: function (value) {
            return value;
          },
        },
      },
    });

    
  }
  updateResponse(cnt)
  setResponseFactors()
  pushYRangeReset()
  updateHTMLValues()
});

function updateHTMLValues() {
  // update  HTML values
  $("#p0").html(math.round(p0));
  $("#f").html(math.round(f * 10) / 10);
  $("#EI").html(math.round(EI / 1000000 * 10) / 10);
  $("#m").html(math.round(m));
  $("#l").html(math.round(l));
  $("#zeta").html(math.round(zeta * 100) / 100);
  $("#fn").html(math.round(omega_n / 2 / math.PI * 100) / 100);
  $("#m_star").html(math.round(m_tilde * 100) / 100);
  $("#k_star").html(math.round(k_tilde / 1000000 * 100) / 100);
  $("#Vu").html(math.round(Vu * 100) / 100);
  $("#Va").html(math.round(Va * 100) / 100);
  $("#umax").html(math.round(umax * 1000 * 1000) / 1000);
  $("#amax").html(math.round(amax * 1000) / 1000);

  // console.log(u3_max / u1_max)

}

function pushStart() {
  if (startButtonState == false) {
    document.getElementById("startButton").innerHTML = 'Stop';
    document.getElementById("startButton").style.background = '#dc3545';
    document.getElementById("startButton").style.border = '#dc3545';
    startButtonState = true;
    startAnalysis()
  } else {
    document.getElementById("startButton").innerHTML = 'Start';
    document.getElementById("startButton").style.background = '#28a745';
    document.getElementById("startButton").style.border = '#28a745';
    startButtonState = false;
  }
}

function pushYRangeReset() {
  if (viewSwitch === 'u') {
    Y1Range = math.dotMultiply([-1.2, 1.2], math.max(Vu * p0 / k_tilde, math.max(response._data)) * 1000);
  } else {
    Y1Range = math.dotMultiply([-1.2, 1.2], Va * p0 / m_tilde);
  }
  var update = {
    'yaxis.range': Y1Range,   // updates the xaxis range
  };
  Plotly.relayout('plotGraphs', update)
}

function setResponseFactors() {
  updateResponse(1000000)
  console.log('Vd:')
  umax = math.max(response._data);
  amax = math.max(response_a._data);
  Vu = math.max(response._data) / (p0 / k_tilde);
  Va = math.max(response_a._data) / (p0 / m_tilde);
}

function viewchanged() {
  viewSwitch = document.querySelector('input[name="view"]:checked').id;

  pushYRangeReset()
};


